.close-overlay-btn {
  background: #ffffff17;
  border-radius: 100px;
  position: fixed;
  z-index: 100;
  top: 20px;
  right: 220px;
  transition: all 0.2s;

  &:hover {
    background: #ffffff28;
  }

  &:active {
    background: #ffffff44;
  }
}

.vault-stake-container {
  position: relative;
  display: flex;
  position: relative;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  color: white;
  animation: fadeIn 0.4s ease-in;

  .title {
    position: relative;
    font-size: 2em;
  }

  .explainer {
    position: relative;
  }

  .no-items {
    margin-top: 60px;
    background: #af0c0033;
    padding: 10px;
  }

  .form-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    width: 80vw;
    margin: 0px auto;
    margin-top: 30px;

    .form-item-container {
      display: grid;
      justify-items: center;
      margin-top: 20px;

      .form-title {
        font-size: 13px;
      }

      .form-item-inner-container {
        position: relative;
        width: 100px;
        height: 100px;
        margin-inline: 10px;
        display: flex;
        cursor: pointer;
        transition: all 100ms;
        align-items: flex-start;

        .form-item {
          position: relative;
          width: 70px;
          height: 70px;
          margin-inline: 10px;
          margin: 0px auto;
          cursor: pointer;
          transition: all 100ms;
          opacity: 0.8;
        }

        .form-item-selected {
          position: relative;
          width: 100px;
          height: 100px;
          margin-inline: 10px;
          margin: 0px auto;
          cursor: pointer;
          transition: all 100ms;

          .selected-container {
            width: 100%;
            height: 100%;
            background: #f0d000ba;
            display: flex;

            .tick {
              width: 40px;
              margin: auto;
              object-fit: contain;
              height: 40px;
            }
          }
        }

        &:hover {
          .form-item-selected {
            opacity: 0.8;
          }

          .form-item {
            opacity: 1;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.select-plan-main-container {
    &::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    max-width: 100vw;
    overflow: auto;
    margin-top: 40px;
    scrollbar-width: none;
    flex-wrap: wrap;
    justify-content: space-around;
  .plan-container {
    background: #40404021;
    padding: 25px;
    border: 1px solid #ffeb3b;
    border-radius: 8px;
    margin-bottom: 20px;
    max-width: 380px;
    min-width: 290px;
    overflow: hidden;
    margin-inline: 20px;
  }

  .line-break {
    white-space: pre-line;
    width: revert;
    word-wrap: break-word;
  }
}
