.features-main-container {
  width: 100vw;
  background: #e3dfd7;
  padding-block: 100px;

  .title-container {
    text-align: center;
    max-width: 90vw;
    margin: auto;
    .title {
      font-size: 30px;
      font-weight: 900;
    }
    .subtitle {
      font-size: 20px;
      font-weight: 300;
    }
  }
  .features-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    column-gap: 120px;
    .features-section {
      width: 300px;
      .features-content-container {
        padding-block: 50px;
        .icon {
          width: 70px;
          height: 70px;
        }
        .title {
          margin-top: 20px;
          font-size: 20px;
        }
        .description {
          font-weight: 300;
          font-size: 15px;
          margin-top: 10px;
        }
      }
    }
  }
}
