@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
}

html {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(0.5deg, #020408 34%, #20272C 96.6%);
  min-height: -webkit-fill-available;
}

body {
  background-image: url("./assets/images/app-bg.png");
  background-size: 100vw;
  background-repeat: repeat;
}

@media screen and (max-width: 600px) {
  body {
    background-image: url("./assets/images/app-bg.png");
    background-position: top center;
    background-size: 200vw;
    background-repeat: repeat;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wallet-btn {
  background: transparent !important;
  border: 1px solid #f6d400 !important;
  font-family: 'Poppins' !important;
  font-weight: 200 !important;
  font-size: 12px !important;
  padding: 0px 40px !important;
}
/*# sourceMappingURL=app.css.map */

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}