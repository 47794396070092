.vault-multiplier-item-container {
  background: #312745;
  margin-inline: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 11px;
  margin-top: 10px;
  cursor: pointer;
  animation: fadeIn 1s ease-in;
  transition: all 0.3s;
  justify-content: space-between;
  &:hover {
    background: #212a30;
  }

  &:active {
    background: rgb(24, 29, 35);
  }

  .image-container {
    position: relative;
    display: flex;

    .image {
      width: 90px;
      height: 90px;
      border-radius: 7px;
      object-fit: contain;
    }

    .image-text {
      position: absolute;
      bottom: 0px;
      right: 0px;
      background: #ffdd02;
      color: black;
      padding: 4px 10px;
      font-size: 11px;
      font-weight: 300;
      border-radius: 0px 0px 8px 0px;
    }
  }

  .busy-container {
    background: #000000bf;
    width: 190px;
    height: 190px;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    display: flex;

    .busy-loader {
      position: relative;
      width: 30px;
      height: 30px;
      border: 3px solid #f6d400;
      overflow: hidden;
      animation: spin 5s ease infinite;
      margin: auto;
    }

    .busy-loader::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 30px;
      height: 30px;
      background-color: #f6d50088;
      transform-origin: center bottom;
      transform: scaleY(1);
      animation: fill 5s linear infinite;
    }
  }

  .staked-container {
    // margin-top: 20px;
    width: 100%;
    text-align: center;

    .busy-unstaking {
      font-size: 10px;
      margin-top: 8px;
    }

    .staked-info-container {
      width: 100%;
      display: flex;
      font-size: 12px;
      justify-content: space-around;
      // margin-top: 12px;
      border-radius: 0px;
      flex-direction: column;

      .staked-info-section {
        padding-block: 4px;
        .in-use {
          font-size: 7px;
        }
        .UNLIMITED {
          color: rgb(255, 221, 7);
          font-size: 13px;
        }
      }
      .staked-program-info-section {
        padding-block: 4px;
        font-size: 10px;
      }
      .divider {
        width: 80%;
        border-top: 1px solid rgba(255, 255, 255, 0.102);
        margin: auto;
      }
    }
    .activate {
      font-size: 10px;
      margin-top: 10px;
      color: #f6d400;
    }
    .time-left {
      font-size: 15px;
      font-weight: 100;

      .items-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .divider {
          height: 24px;
          width: 1px;
          background: #ffffff1a;
          margin-inline: 2px;
        }

        .item-container {
          position: relative;
          margin-inline: 7px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;

          .item-value {
            position: relative;
            font-weight: 200;
            margin-right: 3px;
          }

          .item-title {
            position: relative;
            font-size: 11px;
            color: #ffdd02;
            font-weight: 200;
          }
        }
      }
    }

    progress {
      border-radius: 70px;
      width: 100%;
      height: 10px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    }

    progress::-webkit-progress-bar {
      background-color: #30383d;
      border-radius: 70px;
    }

    progress::-webkit-progress-value {
      background-color: #06ffc3;
      border-radius: 70px;
      box-shadow: 0px 0px 5px 3px #06ffc56c;
    }
  }
}

@keyframes spin {
  50%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fill {
  25%,
  50% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}
