.landing-button {
  .small {
    background: transparent;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px !important;
    min-width: 87px !important;
    color: #ffffff;
    font-size: 11px !important;
    font-weight: 300;
  }

  .ab-base {
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }

  .primary {
    background: #68af73;
    border-radius: 10px;
    font-size: 28px;
    padding: 12px;
    min-width: 230px;
    color: #ece9e1;
    letter-spacing: 0.5px;
    font-weight: 900;
    font-family: "Kanit";

    &:hover {
      background: #599862;
      border-radius: 8px;
    }

    &:active {
      background: #3c7544;
      border-radius: 5px;
    }
  }

  .light {
    background: transparent;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px;
    min-width: 230px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 300;

    &:hover {
      background: #f0d00015;
    }

    &:active {
      background: #ffffff36;
    }
  }
}
