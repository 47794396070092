.twitter-profile-main-container {
  width: 100vw;
  text-align: center;
  color: white;
  .tweets-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 22px;
  }
}

.twitter-wallet-wrapper {
  width: 100vw;
  padding-top: 10px;
  -webkit-animation: fadeIn 0.5s linear forwards;
  animation: fadeIn 0.5s linear forwards;
  opacity: 1;

  .wallet-btn {
    background: transparent !important;
    border: 1px solid #f6d400 !important;
    font-family: "Poppins" !important;
    font-weight: 200 !important;
    font-size: 12px !important;
    padding: 12px 40px !important;
    margin: auto !important;
    display: flex;
  }

  .connect-alert {
    text-align: center;
    color: white;
    padding-bottom: 30px;
  }
}
