.vault-balance-main-container {
  position: relative;
  display: flex;
  margin: auto;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  .vault-balance-container {
    background: #1d1827;
    margin-inline: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 20px 20px;
    border-radius: 11px;
    min-width: 220px;
    margin-top: 10px;
    animation: fadeIn 1s ease-in;
    .vault-balance-split-container {
      .token-withdraw-container {
        display: flex;
        justify-content: space-between;
        width: 300px;
        .token-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .token-title {
            font-weight: 300;
            font-size: 11px;
          }
          .token-value {
            font-weight: 600;
            color: #f6d400;
            font-size: 21px;
          }
        }
      }
      .reward-pool-alert-container {
        background: #ff00005e;
        padding: 20px;
        width: 300px;
        font-size: 11px;
        margin-top: 10px;
        border-radius: 10px;
        
        .title {
            font-weight: 700;
        }

        .description {
            margin: 0px;
        }
      }
    }

  }
}
