.footer-main-container {
    width        : 100vw;
    text-align   : center;
    background   : #535353;
    padding-block: 24px;
    font-family: "Kanit";

    .anybodies-icon {
        width        : 60px;
        height       : 60px;
        object-fit   : fill;
        margin-bottom: 7px;
    }

    .social-items-container {
        display        : flex;
        align-items    : center;
        justify-content: center;
        flex-wrap      : nowrap;
        flex-direction : row;
        align-content  : center;
        margin-top     : 20px;

        .social-item {
            display        : flex;
            background     : #ffffff17;
            padding        : 10px;
            border-radius  : 100px;
            min-width      : 18px;
            min-height     : 18px;
            justify-content: center;
            align-content  : center;
            margin-inline  : 10px;
            transition     : all 0.3s;
            cursor         : pointer;

            &:hover {
                background: #ffffff1a;
            }

            &:active {
                background: #ffffff2d;
            }

            .social-img {
                object-fit: contain;
                max-width : 15px;
                max-height: 15px;
                margin    : auto;
            }
        }


    }

    .copyrights-text {
        color         : #ffffff;
        margin-top    : 24px;
        font-size     : 12px;
        font-weight   : 300;
        letter-spacing: 2px;
        text-align    : center;
    }
}