.vault-info-section {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    align-content  : center;
    justify-content: center;

    .preview-image {
        object-fit: scale-down;
        margin-top: 40px;
    }
}