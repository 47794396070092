.vault-items-section-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  max-width: 1000px;
  margin: auto;
  margin-top: 20px;
  animation: fadeIn 1s ease-in;
}
.section-title {
  margin: auto;
  text-align: center;
  margin-top: 80px;
  font-size: 30px;
  font-weight: 800;
  background: #ffdd07;
  color: #1c111b;
  letter-spacing: 10px;
  width: fit-content;
  padding: 10px 80px;
  animation: fadeIn 1s ease-in;
}
.section-title-unstaked {
  margin: auto;
  text-align: center;
  margin-top: 80px;
  font-size: 30px;
  font-weight: 200;
  // background: #20282e;
  color: #ffdd07;
  letter-spacing: 10px;
  width: fit-content;
  padding: 10px 80px;
  animation: fadeIn 1s ease-in;
}
