.cta-main-container {
  display: flex;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    gap: 11px;
    .question {
      font-weight: 300;
      font-size: 17px;
    }
  }
}
