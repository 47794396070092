.vault-main-container {
  min-height: 100vh;
  width: 100vw;
  color: white;
  padding-bottom: 100px;
}

.vault-placeholder-main-container {
  color: white;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  letter-spacing: 3px;

  .vault-title {
    font-size: 30px;
    font-weight: 500;
    background: #ffdd02;
    color: black;
    padding: 10px 20px;
    border-radius: 10px;
    opacity: 1;
    -webkit-animation: fadeinout 4s linear forwards infinite;
    animation: fadeinout 4s linear forwards infinite;
    margin-bottom: 50px;
  }
}

// @media screen and (max-width: 500px) {
//   .vault-main-container {
//     color: white;
//     font-family: sans-serif;
//     font-weight: 800;
//     // font-size      : 30px;
//     text-align: center;
//     display: flex;
//     // justify-content: center;
//     align-items: center;
//     min-height: 100vh;
//     flex-direction: column;
//     letter-spacing: 3px;

//     .vault-title {
//       font-size: 20px;
//       font-weight: 500;
//       background: #ffdd02;
//       color: black;
//       padding: 10px 20px;
//       border-radius: 10px;
//       opacity: 1;
//       -webkit-animation: fadeinout 4s linear forwards infinite;
//       animation: fadeinout 4s linear forwards infinite;
//       margin-bottom: 50px;
//     }
//   }
// }

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0.7;
  }

  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0.7;
  }

  50% {
    opacity: 1;
  }
}

.vault-wallet-wrapper {
  width: 100vw;
  padding-top: 70px;

  .wallet-btn {
    background: transparent !important;
    border: 1px solid #f6d400 !important;
    font-family: "Poppins" !important;
    font-weight: 200 !important;
    font-size: 12px !important;
    padding: 12px 40px !important;
    margin: auto !important;
    display: flex;
    justify-content: center;
  }

  .connect-alert {
    text-align: center;
    color: white;
    padding-bottom: 30px;
  }
}

.issue {
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  width: 490px;
  margin: auto;
  margin-top: 20px;
  background: #c1000078;
  border-radius: 20px;
  padding: 20px;
  font-weight: 500;
}
