.slogen-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .small-container {
    flex-direction: column;
    font-size: 60px;
    line-height: 64px;
    justify-content: center;
    width: 26vw;
    height: 8vw;
    .small-inner {
      position: relative;
      margin: auto;
      * {
        animation: middle cubic-bezier(0.29, 0.32, 0, 0.96) 9s infinite;
        font-size: 4vw;
        line-height: 6vw;
      }
      .blur-1 {
        opacity: 0;

        position: absolute;
        animation-delay: 3s;
      }
      .blur-2 {
        position: absolute;
        opacity: 0;

        animation-delay: 6s;
      }
      .normal {
        opacity: 0;

        position: absolute;
      }
    }
  }
  .main-text {
    font-size: 10vw;
    line-height: 10vw;
  }

  // @media screen and (min-width: 320px) {
  //   .main-text {
  //     font-size: 50px;
  //   }
  //   .small-container {
  //     .small-inner {
  //       position: relative;
  //       margin: auto;
  //       * {
  //         animation: middle cubic-bezier(0.29, 0.32, 0, 0.96) 9s infinite;
  //         font-size: 6vw;
  //       }
  //     }
  //   }
  // }

  @keyframes middle {
    0% {
      opacity: 0.1;
      filter: blur(4px);
      transform: translateY(-7vw);
    }

    15% {
      opacity: 1;
      filter: blur(0px);
      transform: translateY(0px);
      color: #68af73;
    }
    30% {
      opacity: 1;
      filter: blur(0px);
      transform: translateY(0px);
      font-size: 6vw;
      color: #68af73;
    }

    55% {
      opacity: 0.3;
      filter: blur(10px);
      transform: translateY(7vw);
    }
    70% {
      opacity: 0.3;
      filter: blur(10px);
      transform: translateY(7vw);
    }
    90% {
      opacity: 0.1;
      filter: blur(10px);
      transform: translateY(7vw);
    }

    100% {
      opacity: 0.1;
      filter: blur(4px);
      transform: translateY(-7vw);
    }
  }

  @media screen and (min-width: 1000px) {
    .main-text {
      font-size: 100px;
      line-height: 100px;
    }
    .small-container {
      width: 310px !important;
      height: 105px;
      .small-inner {
        position: relative;
        margin: auto;

        .blur-1,
        .blur-2,
        .normal {
          font-size: 50px;
          line-height: 100px;
        }
      }
    }

    @keyframes middle {
      0% {
        opacity: 0.1;
        filter: blur(4px);
        transform: translateY(-7vw);
      }

      15% {
        opacity: 1;
        filter: blur(0px);
        transform: translateY(0px);
        color: #68af73;
      }
      30% {
        opacity: 1;
        filter: blur(0px);
        transform: translateY(0px);
        font-size: 70px;
        color: #68af73;
      }

      55% {
        opacity: 0.1;
        filter: blur(10px);
        transform: translateY(7vw);
      }
      70% {
        opacity: 0.1;
        filter: blur(10px);
        transform: translateY(7vw);
      }
      90% {
        opacity: 0.1;
        filter: blur(10px);
        transform: translateY(7vw);
      }

      100% {
        opacity: 0.1;
        filter: blur(4px);
        transform: translateY(-7vw);
      }
    }
  }
}
