.small {
    background   : transparent;
    border       : 1px solid #FFFFFF;
    box-sizing   : border-box;
    border-radius: 5px;
    padding      : 5px !important;
    min-width    : 87px !important;
    color        : #FFFFFF;
    font-size    : 11px  !important;
    font-weight  : 300;
}

.ab-base {
    outline    : none;
    border     : none;
    font-family: 'Poppins';
    cursor     : pointer;
    transition : all 0.1s ease-in-out;
}

.primary {
    background   : #F6D400;
    border-radius: 5px;
    font-size    : 15px;
    padding      : 12px;
    min-width    : 230px;
    color        : black;
    font-weight  : 500;

    &:hover {
        background: #f0d000f6;
    }

    &:active {
        background: #d3b701f6;
    }
}


.light {
    background   : transparent;
    border       : 1px solid #FFFFFF;
    box-sizing   : border-box;
    border-radius: 5px;
    padding      : 12px;
    min-width    : 230px;
    color        : #FFFFFF;
    font-size    : 15px;
    font-weight  : 300;

    &:hover {
        background: #f0d00015;
    }

    &:active {
        background: #ffffff36;
    }
}