.overview-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .spacer {
    width: 90vw;
    margin: auto;
    border-top: 1px solid #b8b3a7;
    margin-block: 120px;
    opacity: 0.75;
  }
  .title-container {
    text-align: center;
    max-width: 90vw;
    .title {
      font-size: 30px;
      font-weight: 900;
    }
    .subtitle {
      font-size: 20px;
      font-weight: 300;
    }
  }

  .contect-container {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 80px;
    margin-bottom: 120px;
    .preview-container {
      .img {
        width: 610px;
        max-width: 90vw;
        object-fit: contain;
      }
    }

    .list-continer {
      display: grid;
      width: 480px;
      gap: 30px;
      max-width: 90vw;
      .item-container {
        display: flex;

        flex-direction: row;
        align-items: flex-start;
        .icon-container {
          margin-right: 30px;

          .icon-bg {
            width: 60px;
            height: 60px;
            background: #e5e1d3;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            box-shadow: -1px 2px 3px #00000024;
            .icon {
              max-width: 30px;
              max-height: 30px;
              object-fit: contain;
            }
          }
        }

        .content-container {
          display: flex;
          flex-direction: column;
          .title {
            font-weight: 900;
            font-size: 16px;
          }
          .description {
            font-weight: 300;
            font-size: 13px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
