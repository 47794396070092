.select-multiplier-plan-main-container {
  display: flex;
  overflow: auto;
  max-width: 100vw;
  flex-wrap: wrap;
    justify-content: center;
  .plan-container {
    background: #40404021;
    padding: 25px;
    border: 1px solid #ffeb3b;
    border-radius: 8px;
    margin-bottom: 20px;
    max-width: 380px;
    margin-top: 40px;
    min-width: 290px;
    overflow: hidden;
    margin-inline: 20px;
  }

  .line-break {
    white-space: pre-line;
    width: revert;
    word-wrap: break-word;
  }
}
