.vault-stats-section-container {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    flex-wrap      : wrap;
    align-content  : center;
    margin-top     : 60px;
    animation      : fadeIn 1s ease-in;


    .vault-stats-item-container {
        background     : #14191d;
        margin-inline  : 5px;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-content  : center;
        align-items    : center;
        padding        : 0px 10px;
        border-radius  : 11px;
        min-width      : 200px;
        min-height     : 150px;
        margin-top: 10px;
        animation      : fadeIn 1s ease-in;

        .value {
            position     : relative;
            font-size    : 1em;
            font-size    : 26px;
            margin-bottom: 16px;
            animation    : fadeIn 1s ease-in;
        }

        .title {
            position   : relative;
            font-size  : 15px;
            font-weight: 200;
            animation  : fadeIn 1s ease-in;
        }


        .box {
            perspective : 120px;
            margin-right: 15px;
            animation      : fadeIn 1s ease-in;

            .coin {
                width    : 0.5em;
                height   : 0.5em;
                border   : 2px solid #ffffff;
                animation: spin 1.5s ease-in-out infinite;
            }

            @keyframes spin {
                to {
                    transform: rotateY(540deg);
                }
            }
        }

    }
}