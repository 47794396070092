.minting-button-main-container {
    padding      : 50px 20px;
    border       : 1px solid #ffffff3d;
    border-radius: 10px;
    min-width: 200px;
    margin-top   : 40px;
    background   : #1e1e1e8a;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    animation       : fadeIn 0.2s ease-in;
    
    .message {
        font-weight: 200;
        font-size  : 24px;
    }

    .may-take {
        font-size  : 12px;
        margin-top : 10px;
        font-weight: 600;
        color      : #ffffff7a;
    }
  
}

.wallet-btn {
    background : transparent !important;
    border     : 1px solid #f6d400 !important;
    font-family: 'Poppins' !important;
    font-weight: 200 !important;
    font-size  : 12px !important;
    padding    : 0px 40px !important;
    margin: auto;
}

.minting-button-loader {
    position     : relative;
    width        : 30px;
    height       : 30px;
    border       : 3px solid #f6d400;
    overflow     : hidden;
    animation    : spin 5s ease infinite;
    margin       : auto;
    margin-bottom: 20px;
}

.minting-button-loader::before {
    content         : '';
    position        : absolute;
    top             : 0px;
    left            : 0px;
    width           : 30px;
    height          : 30px;
    background-color: #f6d50088;
    transform-origin: center bottom;
    transform       : scaleY(1);
    animation       : fill 5s linear infinite;
}

@keyframes spin {

    50%,
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fill {

    25%,
    50% {
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}