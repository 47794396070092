.stats-container {
  background: #68af73;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  text-align: center;
  .stats-section {
    width: 230px;
    .stats-content-container {
      padding-block: 50px;
      .value {
        font-size: 40px;
      }
      .description {
        font-weight: 300;
      }
    }
  }
}
