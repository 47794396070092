.multiplier-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #00000054;
  padding-block: 20px;
  margin-block: 20px;

  .multiplier-title {
    margin: auto;
    text-align: center;
    margin-top: 0px;
    font-size: 17px;
    font-weight: 900;
    color: #ffdd07;
    letter-spacing: 4px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 80px;
    animation: fadeIn 1s ease-in;
  }
  .multiplier-actions-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
  .multiplier-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
