.vault-toolbar-container {
    margin-inline: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 7px 0px;
    border-radius: 11px;
    min-width: 180px;
    margin-top: 20px;
    animation: fadeIn 1s ease-in;
    align-items: stretch;
    flex-wrap: wrap;

    .action-container {
        margin-inline: 5px;
    }

    .vertical-border {
        position     : relative;
        height       : 40px;
        width        : 2px;
        margin-inline: 26px;
        background   : #ffffff69;
        border-radius: 10px;
    }

    .auto-staking-container {
        background     : #2d2444;
        margin-inline  : 5px;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-content  : center;
        align-items    : center;
        padding        : 10px 10px;
        border-radius  : 11px;
        min-width      : 200px;
        flex-wrap      : nowrap;
        margin: 10px;

        .status-container {
            margin-right: 23px;

            .title {
                font-size: 9px;
            }

            .status-container {
                display        : flex;
                align-items    : center;
                justify-content: flex-start;
                flex-direction : row;
                flex-wrap      : nowrap;
                animation  : fadeIn 1s ease-in;

                .status-indicator-off {
                    width        : 10px;
                    height       : 10px;
                    border-radius: 20px;
                    background   : red;
                    margin-right : 15px;
                }

                .status-indicator-on {
                    width        : 10px;
                    height       : 10px;
                    border-radius: 20px;
                    background   : rgb(0, 163, 8);
                    margin-right : 15px;
                }

                .status {
                    font-size  : 21px;
                    font-weight: 100;
                }
            }

        }

    }


    .balls {
        width          : 4em;
        display        : flex;
        flex-flow      : row nowrap;
        align-items    : center;
        justify-content: space-between;
        margin         : auto;
        animation  : fadeIn 1s ease-in;
    }

    .balls div {
        width           : 0.8em;
        height          : 0.8em;
        border-radius   : 50%;
        background-color: #ffffff;
    }

    .balls div:nth-of-type(1) {
        transform: translateX(-100%);
        animation: left-swing 0.5s ease-in alternate infinite;
    }

    .balls div:nth-of-type(3) {
        transform: translateX(-95%);
        animation: right-swing 0.5s ease-out alternate infinite;
    }

    @keyframes left-swing {

        50%,
        100% {
            transform: translateX(95%);
        }
    }

    @keyframes right-swing {
        50% {
            transform: translateX(-95%);
        }

        100% {
            transform: translateX(100%);
        }
    }

    .box {
        perspective : 120px;
        margin-right: 15px;
        animation  : fadeIn 1s ease-in;

        .coin {
            width        : 0.5em;
            height       : 0.5em;
            border       : 2px solid #ffffff;
            animation    : spin 1.5s ease-in-out infinite;
        }

        @keyframes spin {
            to {
                transform: rotateY(540deg);
            }
        }
    }

}


.toolbat-alert-container {
    width: 340px;
    margin: auto;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    text-align: center;
}