.verify-container {
    // @import 'bootstrap/dist/css/bootstrap.min.css';
    color          : white;
    width          : 100vw;
    min-height     : 100vh;
    display        : flex;
    justify-content: center;
    align-items    : center;

    .inner-container {
        width          : 60vw;
        text-align     : center;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;
        align-content  : center;

        a {
            color: #f6d400;
        }

        .balls {
            width          : 4em;
            display        : flex;
            flex-flow      : row nowrap;
            align-items    : center;
            justify-content: space-between;
            margin         : auto;
            animation      : fadeIn 1s ease-in;
        }

        .balls div {
            width           : 0.8em;
            height          : 0.8em;
            border-radius   : 50%;
            background-color: #ffffff;
        }

        .balls div:nth-of-type(1) {
            transform: translateX(-100%);
            animation: left-swing 0.5s ease-in alternate infinite;
        }

        .balls div:nth-of-type(3) {
            transform: translateX(-95%);
            animation: right-swing 0.5s ease-out alternate infinite;
        }
    }

    .title {
        font-weight: 700;
        font-size  : 30px;
        color      : #ffeb3b;
    }

    .success-container {
        background: #0000008a;
        padding   : 20px;
        margin-top: 22px;

        .verified {
            font-size  : 24px;
            font-weight: 100;
        }

        .verified-message {
            font-size     : 14px;
            width         : 340px;
            margin        : auto;
            margin-top    : 20px;
            padding-inline: 30px;
        }

    }
}

.wallet-btn {
    background: transparent !important;
        border: 1px solid #f6d400 !important;
        font-family: 'Poppins' !important;
        font-weight: 200 !important;
        font-size: 12px !important;
        padding: 12px 40px !important;
        margin: auto !important;
        display: flex;
}